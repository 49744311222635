<template>
  <div id="back-to-top" class="c-back-to-top" :class="{hide: isHidden}">
    <a href="#top" class="c-back-to-top__btn" @click="scrollUp">
      <span class="c-back-to-top__text u-hidden-visually">
        {{ gettext('Back to top') }}
      </span>
    </a>
  </div>
</template>

<script>
  export default {
    name: 'BackToTop',

    props: {
      showAfterOffset: {
        type: Number,
        default: 600
      },
    },

    data() {
      return {
        yOffset: window.pageYOffset
      }
    },

    computed: {
      isHidden() {
        return this.yOffset < this.showAfterOffset
      }
    },

    methods: {
      scrollListener() {
        this.yOffset = window.pageYOffset
      },
      scrollUp(e) {
        e.preventDefault()
        // todo: animate
        window.scrollTo(0, 0)
      }
    },

    created() {
      window.addEventListener('scroll', this.scrollListener)
    },
    
    destroyed() {
      window.removeEventListener('scroll', this.scrollListener)
    }
  }
</script>
