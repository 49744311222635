var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-back-to-top",
      class: { hide: _vm.isHidden },
      attrs: { id: "back-to-top" }
    },
    [
      _c(
        "a",
        {
          staticClass: "c-back-to-top__btn",
          attrs: { href: "#top" },
          on: { click: _vm.scrollUp }
        },
        [
          _c("span", { staticClass: "c-back-to-top__text u-hidden-visually" }, [
            _vm._v("\n      " + _vm._s(_vm.gettext("Back to top")) + "\n    ")
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }