import Vue from 'vue'

import MQ from 'vue-match-media/dist'

// import StickyNav from './StickyNav'
import BackToTop from './BackToTop'
import Plyr from './Plyr'
import PlyrOverlay from './PlyrOverlay'
import Popup from './Popup'
import VModal from 'vue-js-modal'

// import 'core-js/es6/set'
// import 'core-js/fn/array/from'
// import 'core-js/es6/promise'
//
// import 'babel-polyfill'
// import 'custom-event-polyfill'
// import 'url-polyfill'

if (!window.django) {
  console.error("window.django not defined - ensure Django's jsi18n is loaded before this script")
}

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector
}
if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this
    if (!document.documentElement.contains(el)) return null
    do {
      if (el.matches(s)) return el
      el = el.parentElement || el.parentNode
    } while (el !== null && el.nodeType === 1)
    return null
  }
}

Vue.use(MQ)

Vue.prototype.gettext = window.django.gettext
Vue.prototype.pgettext = window.django.pgettext
Vue.prototype.lang = window.django.LANGUAGE_CODE

// The only reason this plyr sprite madness is here is because it's comparatively
// less insane than the efforts required to make svg external linking polyfills
// actually function. If that situation should change, then awesome.
const plyrCreatedEvent = new CustomEvent('plyrCreated')
const createPlyrSprites = function() {
  // only need this once
  document.removeEventListener('plyrCreated', createPlyrSprites)

  if (document.getElementById('plyr-sprites')) return

  const ajax = new XMLHttpRequest()
  ajax.open('GET', '/static/images/icons/plyr/plyr.svg', true)
  ajax.send()
  ajax.onload = function(e) {
    const spriteEl = document.createElement('div')
    spriteEl.setAttribute('id', 'plyr-sprites')
    spriteEl.innerHTML = ajax.responseText
    document.body.insertBefore(spriteEl, document.body.childNodes[0])
  }
}
document.addEventListener('plyrCreated', createPlyrSprites)
Vue.directive('plyr-instance', {
  inserted(el) {
    document.dispatchEvent(plyrCreatedEvent)
  }
})

// Vue.component(StickyNav.name, StickyNav)
Vue.component(BackToTop.name, BackToTop)
Vue.component(Plyr.name, Plyr)
Vue.component(PlyrOverlay.name, PlyrOverlay)
Vue.component(Popup.name, Popup)
Vue.use(VModal)

export const bus = new Vue()

const vueApps = document.getElementsByClassName('vue-app')

for (let i = 0; i < vueApps.length; i++) {
  const element = vueApps[i]
  const newVue = new Vue({
    el: element,
    data: {
      document: document,
      window: window
    }
  })
}

window.Vue = Vue

/// ////////////////////////////////////////////

// fast and dirty accordion for now

const accordions = Array.from(document.getElementsByClassName('js-accordion'))

accordions.forEach(function(accordion) {
  const items = Array.from(accordion.getElementsByClassName('js-accordion-item'))
  const onlyOne = !accordion.classList.contains('js-accordion-multiopen')
  // const scrollToTop = accordion.classList.contains('js-accordion-scroll')

  items.forEach(function(item) {
    const itemHeading = Array.from(item.children)[0]
    const itemBody = Array.from(item.children)[1]
    const siblingItems = items.filter(function(i) { return i !== item })

    if (item.classList.contains('is-open')) itemBody.style.maxHeight = itemBody.scrollHeight + 'px'

    itemHeading.addEventListener('click', function(e) {
      item.classList.toggle('is-open')

      const isOpen = item.classList.contains('is-open')
      itemBody.setAttribute('aria-hidden', !isOpen)
      itemBody.style.maxHeight = isOpen ? itemBody.scrollHeight + 'px' : null

      if (onlyOne) {
        for (let i = 0; i < siblingItems.length; i++) {
          const sibItemBody = Array.from(siblingItems[i].children)[1]
          siblingItems[i].classList.remove('is-open')
          sibItemBody.setAttribute('aria-hidden', true)
          sibItemBody.style.maxHeight = null
        }
      }
      // if (scrollToTop) {
      //     // todo
      // }
    })
  })

  // Requested by the Legal Department to add Open All and Close All buttons
  // Button to open all accordion items
  const openAllButton = document.querySelector('.btn-open-all')
  openAllButton.addEventListener('click', function() {
    items.forEach(function(item) {
      item.classList.add('is-open')
      const itemBody = Array.from(item.children)[1]
      itemBody.setAttribute('aria-hidden', false)
      itemBody.style.maxHeight = itemBody.scrollHeight + 'px'
    })
  })

  // Button to close all accordion items
  const closeAllButton = document.querySelector('.btn-close-all')
  closeAllButton.addEventListener('click', function() {
    items.forEach(function(item) {
      item.classList.remove('is-open')
      const itemBody = Array.from(item.children)[1]
      itemBody.setAttribute('aria-hidden', true)
      itemBody.style.maxHeight = null
    })
  })
})

Vue.directive('autofocus', {
  inserted(el) {
    el.focus()
  }
})

// if location matches /checkout/?
window.addEventListener('hashchange', function(ev) {
  store.dispatch('hashChanged', window.location.hash)
})

const externalLinks = () => {
  const currentHostname = window.location.hostname
  for (let links = document.getElementsByTagName('a'), i = 0; i < links.length; i++) {
    const link = links[i]
    link.getAttribute('href') &&
      link.hostname !== currentHostname &&
      (link.getAttribute('href').match(/^http:\/\//) || link.getAttribute('href').match(/^https:\/\//)) &&
      !link.getAttribute('href').match(/^mailto:/) &&
      !link.getAttribute('href').match(/^tel:/) &&
      !link.classList.contains('js-no-ext-icon') &&
      (link.target = '_blank')
  }
}
window.onload = externalLinks
