var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", { attrs: { name: "popup", height: "auto" } }, [
    _c("div", { staticClass: "popup-outside" }, [
      _c("div", { staticClass: "popup-inside" }, [
        _c("span", { staticClass: "close", on: { click: _vm.close_modal } }, [
          _vm._v("×")
        ]),
        _vm._v(
          "\n      By accessing this site, you represent that you are currently employed as a Crown attorney within Canada. You understand that the information made available through this site is provided only for informational purposes (of yourself, your colleagues or the court) and as a starting reference on various points of law. No legal advice is given or intended. Use of the site is not tracked on an individual level, though C3P will note which areas of the site are being used more frequently as a way to gauge which areas should be updated more often.\n    "
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }