<script>
import Plyr from 'plyr/dist/plyr.polyfilled'
import { bus } from './index'
import PlyrOverlay from './PlyrOverlay'

export default {
  name: 'Plyr',

  components: {
    PlyrOverlay
  },

  props: {
    options: {
      type: Object,
      required: false,
      default() {
        return {
          loadSprite: false,
          disableContextMenu: false,
          speed: {
            // 7 settings seems a bit excessive for our purposes
            options: [0.5, 1, 1.5, 2],
          },
          quality: {
            default: 720
          },
          controls: [
            'play-large',
            // 'restart',
            // 'rewind',
            'play',
            // 'fast-forward',
            'progress',
            'current-time',
            'mute',
            'volume',
            'captions',
            'settings',
            // 'pip',
            // 'airplay',
            'fullscreen',
          ],
          
          i18n: {
            restart: gettext('Restart'),
            rewind: gettext('Rewind {seektime}s'),
            play: gettext('Play'),
            pause: gettext('Pause'),
            fastForward: gettext('Forward {seektime}s'),
            seek: gettext('Seek'),
            seekLabel: gettext('{currentTime} of {duration}'),
            played: gettext('Played'),
            buffered: gettext('Buffered'),
            currentTime: gettext('Current time'),
            duration: gettext('Duration'),
            volume: gettext('Volume'),
            mute: gettext('Mute'),
            unmute: gettext('Unmute'),
            enableCaptions: gettext('Enable captions'),
            disableCaptions: gettext('Disable captions'),
            enterFullscreen: gettext('Enter fullscreen'),
            exitFullscreen: gettext('Exit fullscreen'),
            frameTitle: gettext('Player for {title}'),
            captions: gettext('Captions'),
            settings: gettext('Settings'),
            menuBack: gettext('Go back to previous menu'),
            speed: gettext('Speed'),
            normal: gettext('Normal'),
            quality: gettext('Quality'),
            loop: gettext('Loop'),
            start: gettext('Start'),
            end: gettext('End'),
            all: gettext('All'),
            reset: gettext('Reset'),
            disabled: gettext('Disabled'),
            enabled: gettext('Enabled'),
            qualityBadge: {
              2160: '4K',
              1440: 'HD',
              1080: 'HD',
              720: 'HD',
              576: 'SD',
              480: 'SD',
            },
          },
        }
      }
    },
    emit: {
      type: Array,
      required: false,
      default() { return [] }
    }
  },
  
  data() {
    return {
      player: {},
      playerCurrentTime: 0,
      playerDuration: 0
    }
  },
  
  methods: {
    emitPlayerEvent(e) {
      this.$emit(e.type, e)
    }
  },

  created() {
    bus.$on('plyr:playing', (id) => {
      // Don't let two videos play simultaneously; that way lies madness
      if (id !== this.player.id) this.player.pause()
    })
  },
  
  mounted() {
    const video = this.$el.querySelector('video')
    
    this.player = new Plyr(video, this.options)

    this.emit.forEach(el => {
      this.player.on(el, this.emitPlayerEvent)
    })

    this.player.on('durationchange loadeddata loadedmetadata', (e) => {
      console.log('heard durationchange', e)
      this.playerDuration = this.player.duration
    })
    this.player.on('timeupdate', (e) => {
      this.playerCurrentTime = this.player.currentTime
    })

    this.player.on('playing', (e) => {
      bus.$emit('plyr:playing', this.player.id)
      window._paq.push(['trackEvent', 'video-event', 'started playing', this.player.media.currentSrc])
    })
    this.player.on('ended', (e) => {
      bus.$emit('plyr:ended', this.player.media.currentSrc)
      window._paq.push(['trackEvent', 'video-event', 'ended/completed playing', this.player.media.currentSrc])
    })
    this.player.on('error', (e) => {
      window._paq.push(['trackEvent', 'video-event', 'video error', e])
    })

    this.$nextTick(() => {
      // plyr fires off captions events every time a video is loaded, depending on what it remembers of a user's preferences,
      // but that's not as useful to us for tracking purposes as knowing when someone actually toggles the captions, so:
      const captionsBtn = this.$el.querySelector('button[data-plyr="captions"]')
      if (captionsBtn) {
        captionsBtn.addEventListener('click', (e) => {
          window._paq.push([
            'trackEvent',
            'video-event',
            `captions ${this.player.captions.active ? 'enabled' : 'disabled'}`,
            this.player.media.currentSrc
          ])
        })
      }
    })
  },
  
  beforeDestroy() {
    this.player.destroy()
  }
}
  
</script>
