<template>
  <modal
    name="popup"
    height="auto">
    <div class="popup-outside">
      <div class="popup-inside">
        <span
          class="close"
          @click="close_modal"
        >&times;</span>
        By accessing this site, you represent that you are currently employed as a Crown attorney within Canada. You understand that the information made available through this site is provided only for informational purposes (of yourself, your colleagues or the court) and as a starting reference on various points of law. No legal advice is given or intended. Use of the site is not tracked on an individual level, though C3P will note which areas of the site are being used more frequently as a way to gauge which areas should be updated more often.
      </div>
    </div>
  </modal>
</template>

<style>

</style>

<script>
module.exports = {
  name: 'popup',
  data() {
    return {
      popupRead: ''
    }
  },
  methods: {
    close_modal: function(e) {
      this.$modal.hide('popup')
    }

  },
  mounted() {
    // ready didn't really work for me, but at least this way we can easily change to something else
    this.popupRead = window.sessionStorage.getItem('popupRead')
    if (!this.popupRead) {
      this.$modal.show('popup')
      window.sessionStorage.setItem('popupRead', 'yes')
    }
  }
}
</script>
<style scoped>
  /* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position:relative;
    top: -20px
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
</style>
