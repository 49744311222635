<template>
  <div class="plyr-overlay" v-show="show">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PlyrOverlay',

  props: {
    showAt: {
      type: Number,
      required: true
    },
    showUntil: {
      type: Number
    },
    currentTime: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      required: true
    }
  },

  data() {
    return {}
  },

  computed: {
    show() {
      let showStart = this.showAt < 0 ? (this.duration + this.showAt) : this.showAt
      let showEnd = this.showUntil

      return showStart <= this.currentTime && ( showEnd === undefined || this.currentTime < showEnd )
    }
  },

}

</script>
